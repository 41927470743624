<template>
    <b-card-code no-body title="Create new role">
        <b-row class="pb-2">
            <b-col md="6">
                <div class="px-2">
                <!-- form -->
                <validation-observer ref="roleCreateForm">
                    <b-form
                    class="auth-register-form mt-2"
                    @submit.prevent="createRole"
                    >
                    <!-- name -->
                    <b-form-group>
                        <validation-provider
                        #default="{ errors }"
                        name="name"
                        vid="name"
                        rules="required"
                        >
                        <b-form-input
                            id="name"
                            v-model="name"
                            name="name"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group>
                        <v-select
                            v-model="selected_permissions"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            label="name"
                            :options="permissions"
                            placeholder="Permissions"
                        />
                    </b-form-group>

                    <b-button
                        variant="primary"
                        type="submit"
                        :disabled="btnDisabled"
                    >
                        Create role
                    </b-button>
                    <div
                        v-if="loading"
                        class="d-flex justify-content-center mt-2"
                    >
                        <b-spinner variant="primary" label="Loading..." />
                    </div>
                    </b-form>
                </validation-observer>
                </div>
            </b-col>
        </b-row>
    </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BButton, BFormInput, BForm, BFormGroup, BRow, BCol, BInputGroup, BInputGroupAppend } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { BSpinner } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from 'vue-select'

export default {
    components: {
        BCardCode,
        BRow,
        BCol,
        BButton,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        ValidationProvider,
        ValidationObserver,
        BInputGroupAppend,
        BSpinner,
        vSelect,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            name: "",
            selected_permissions: null,
            permissions: [],

            // validation
            required,
            email,
            btnDisabled: false,
            loading: false,
            isAvailableUsername: 1,
            errors: [],
            slugFlag: false,
            slugString: "",
        }
    },
    computed: {        
        passwordToggleIcon() {
            return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
        },
        cPasswordToggleIcon() {
            return this.cPasswordFieldType === "cPassword" ? "EyeIcon" : "EyeOffIcon";
        }
    },
    created() {
        //Return permissions list
        this.$http.get("/api/auth/admin/permissions")
            .then((res) => {
                if(res != undefined) {
                    this.permissions = res.data
                }
            })
            .catch((err) => {
                console.log(err)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })
    },
    methods: {
        // Request to create role
        createRole() {
            let role = {
                "name": this.name,
                "permissions": this.selected_permissions,
            }

            this.$refs.roleCreateForm.validate().then(success => {
                if (success) {
                    this.$http.post("/api/auth/admin/roles", role)
                    .then((res) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Role successfully created",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });

                        // Reset form
                        this.name = ""
                        this.selected_permissions = []

                        // Redirect to roles list
                        this.$router.push("/roles");
                    })
                    .catch((error) => {
                        let errorMsg = "There was an error!"
                        if(error.response.data != undefined && error.response.data.errors.name != undefined) {
                            errorMsg = error.response.data.errors.name[0]
                        }

                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: errorMsg,
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            });
        },
    },
}
</script>

<style scoped>
    option[disabled] { background-color: #D0D0D0; }
</style>

<style>
    .vs__selected {
        background-color: #59949c!important;
    }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
